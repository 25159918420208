import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../Components/Firebase";
import '../Styles/Components/Head.css';
import Loading from "./Loading";
import { CiLogout } from "react-icons/ci";
import { auth } from "../Components/Firebase";

function Head() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [userData, setUserData] = useState(null);
    const { userid } = useParams();

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userDoc = await db.collection("Teachers").doc(userid).get();
                if (userDoc.exists) {
                    setUserData(userDoc.data());
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        if (userid) {
            fetchUserData();
        }
    }, [userid, userData]);

    const formatTime = (date) => {
        return date.toLocaleTimeString('he-IL', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    const formatDate = (date) => {
        return date.toLocaleDateString('he-IL', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        try {
            const updateData = {
                status: newStatus,
            };

            if (newStatus === "זמין" || newStatus === "לא זמין") {
                updateData.currentClass = '';
                
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }

            await db.collection("Teachers").doc(userid).update(updateData);

            setUserData((prevData) => ({
                ...prevData,
                status: newStatus,
                ...(newStatus === "זמין" || newStatus === "לא זמין" ? { currentClass: '' } : {}),
            }));
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            window.location.href = "/";
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    return (
        <React.Fragment>
            <div className="HeadContent">
                <div className="Head1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="86" viewBox="0 0 73 86" fill="none">
                        <path d="M3.82152 3H36.6967M69.5718 3H36.6967M36.6967 3V55.6394M3.82145 30.6306C-6.06133 93.9592 76.6309 106.665 69.5718 30.6306" stroke="white" strokeWidth="5" strokeLinecap="round"/>
                    </svg>
                    <h1>{formatTime(currentDate)}</h1>
                    <h1>{formatDate(currentDate)}</h1>
                </div>

                <div className="Head2">
                    {userData ? (
                        <div className="UserProfile">
                            <div className="Data1">
                                <button onClick={handleLogout}><CiLogout size={30}/></button>
                                <img src={userData.imageUrl} alt="User Profile" className="UserImage" onContextMenu={(e) => e.preventDefault()}
                                onDragStart={(e) => e.preventDefault()}/>
                                <h2 dir="rtl">שלום {`${userData.firstName} ${userData.lastName}`}</h2>
                            </div>

                            <div className="Data2" dir="rtl">
                                <h2>{`${userData.schoolName}`}</h2>
                                <p style={{color: '#fff', fontFamily: 'MainFont'}}>הסטטוס שלי :</p>
                                <select value={userData.status} onChange={handleStatusChange} className="StatusSelect">
                                    <option value="זמין">זמין</option>
                                    <option value="בשיעור">בשיעור</option>
                                    <option value="לא זמין">לא זמין</option>
                                </select>
                            </div>
                        </div>
                    ) : (
                        <Loading/>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Head;
