import React, { useEffect, useState } from "react";
import Head from "../../Components/Head";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../Components/Firebase";
import '../../Styles/Pages/Manager/ManagerFunctions.css';
import TeachersCountAndSort from "../../Functions/Manager/Data/TeachersCountAndSort";
import StudentsInSchoolCount from "../../Functions/Manager/Data/StudentsInSchoolCount";
import FetchDropoutsCount from "../../Functions/Manager/Data/FetchDropoutsCount";
import FetchExitPreCount from "../../Functions/Manager/Data/FetchExitPreCount";

function ManagerHome() {
  const [schoolId, setSchoolId] = useState(null);
  const { userid } = useParams();
  const nav = useNavigate();

  useEffect(() => {

    window.document.title = "Teacher click Manager"

    const checkUser = async () => {
      const user = auth.currentUser;
      if (!user) {
        nav(`/CheckUser/${userid}`);
      }
    };

    const fetchUserData = async () => {
      try {
        const userDoc = await db.collection("Teachers").doc(userid).get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setSchoolId(userData.schoolName);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    checkUser();
    fetchUserData();
  }, [nav, userid]);

  return (
    <React.Fragment>
      <Head />

      <div className="ManagerFun">
        <div className="ManagerBtn" dir="rtl">
          <button onClick={() => nav(`/Manager/${userid}/SchoolMain/${schoolId}`)}>הגדרות בית הספר</button>
          <button onClick={() => nav(`/Manager/${userid}/ViewTeachers`)}>מורים</button>
          <button onClick={() => nav(`/Manager/${userid}/${schoolId}/ManageStudents`)}>תלמידים</button>
          <button onClick={() => nav(`/Manager/${userid}/${schoolId}/Dropouts`)}>מערכת נשירה (תלמידים)</button>
          <button onClick={() => nav(`/Manager/${userid}/${schoolId}/ExitPre`)}>הרשאות יציאה לתלמידים</button>
          <button onClick={() => nav(`/Manager/${userid}/${schoolId}/Calendar`)}>לוח שנה</button>
          <button>מערכת מילוי אוטומטי (מורים)</button>
          <button>הסכמים לחתימה</button>
          <button>הגדרות חשבון</button>
        </div>

        <div className="ManagerData">
          <h1>צפייה מהירה</h1>

          <div className="DataContainer">
            <div>
              <h1>מורים</h1>
              <TeachersCountAndSort />
            </div>

            <div>
              <h1>תלמידים</h1>
              <StudentsInSchoolCount/>
            </div>

            <div>
              <h1>מערכת נשירה (להיום) </h1>
              <FetchDropoutsCount/>
            </div>

            <div>
              <h1>הרשאות יציאה</h1>
              <FetchExitPreCount/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ManagerHome;
