import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Components/Firebase";
import '../../Functions/Styles/Both/CheckingStudentAttendance.css';
import Loading from "../../Components/Loading";
import { IoClose } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";

function CheckingStudentAttendance({ isOpen, onClose }) {
    const { userid } = useParams();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [schoolName, setSchoolName] = useState("");
    const [teacherName, setTeacherName] = useState("");
    const [messagesLang, setMessagesLang] = useState("");

    useEffect(() => {
        if (isOpen) {
            const fetchStudents = async () => {
                try {
                    const teacherDoc = await db.collection("Teachers").doc(userid).get();
                    
                    if (teacherDoc.exists) {
                        const teacherData = teacherDoc.data();
                        const currentClass = teacherData.currentClass;
                        const school = teacherData.schoolName;
                        const name = `${teacherData.firstName} ${teacherData.lastName}`;

                        setSchoolName(school);
                        setTeacherName(name);

                        const schoolDoc = await db.collection("Schools").doc(school).get();
                        if (schoolDoc.exists) {
                            const schoolData = schoolDoc.data();
                            setMessagesLang(schoolData.MessagesLang || '');
                        }

                        if (currentClass) {
                            const studentsSnapshot = await db
                                .collection("Schools")
                                .doc(school)
                                .collection("Students")
                                .where("class", "==", currentClass)
                                .get();

                            const studentsData = studentsSnapshot.docs.map(doc => ({
                                id: doc.id,
                                firstName: doc.data().firstName,
                                lastName: doc.data().lastName,
                                attendanceStatus: doc.data().status || null,
                                preM: doc.data().preM,
                                phoneNumbers: doc.data().phoneNumbers
                            }));

                            setStudents(studentsData);
                        } else {
                            console.error("No current class ID found for this teacher");
                        }
                    } else {
                        console.error("Teacher document not found");
                    }
                } catch (error) {
                    console.error("Error fetching students: ", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchStudents();
        }
    }, [isOpen, userid, students]);

    useEffect(() => {
        const updateStudentAttendanceStatus = async () => {
            if (students.length > 0) {
                for (const student of students) {
                    const studentDoc = await db.collection("Schools")
                        .doc(schoolName)
                        .collection("Students")
                        .doc(student.id)
                        .get();

                    if (studentDoc.exists) {
                        const studentData = studentDoc.data();
                        setStudents(prevStudents =>
                            prevStudents.map(s =>
                                s.id === student.id
                                    ? { ...s, attendanceStatus: studentData.status || null }
                                    : s
                            )
                        );
                    }
                }
            }
        };

        updateStudentAttendanceStatus();
    }, [students, schoolName]);

    const markAttendance = async (studentId, status, preM) => {
        const student = students.find(s => s.id === studentId);
        if (!student) return;

        const { firstName, lastName, phoneNumbers } = student;
        const studentName = `${firstName} ${lastName}`;
        let phoneNumber = phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers[0] : 'not Available';

        if (phoneNumber.startsWith("0")) {
            phoneNumber = `972${phoneNumber.substring(1)}`;
        } else if (phoneNumber.startsWith("+")) {
            phoneNumber = `972${phoneNumber.substring(3)}`;
        }

        try {
            await db.collection("Schools")
                .doc(schoolName)
                .collection("Students")
                .doc(studentId)
                .update({
                    status: status === "present" ? "Available" : "notAvailable"
                });

            if (status === "absent") {
                await addDropoutEntry(studentId, preM, studentName, phoneNumber);
            }

            setStudents(prevStudents => 
                prevStudents.map(student => 
                    student.id === studentId 
                        ? { ...student, status: status === "present" ? "Available" : "notAvailable" }
                        : student
                )
            );
        } catch (error) {
            console.error("Error updating attendance status: ", error);
        }
    };

    const addDropoutEntry = async (studentId, preM, studentName, phoneNumber) => {
        try {
            const currentTime = new Date();
            const formattedDate = currentTime.toLocaleDateString();
            const formattedTime = currentTime.toLocaleTimeString();
            
            await db.collection("Schools").doc(schoolName).collection("Dropouts").add({
                studentId,
                date: formattedDate,
                time: formattedTime,
                teacherName: teacherName,
                preM: preM,
                phoneNumber: phoneNumber,
                studentName: studentName,
                messagesLang: messagesLang
            });
        } catch (error) {
            console.error("Error adding dropout entry: ", error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay2">
            <div className="modal-content2">
                <button className="close-button2" onClick={onClose}><IoClose color="red" /></button>
                <h1>רישום נוכחות</h1>
                {loading ? (
                    <Loading />
                ) : (
                    <div className="students-list2">
                        {students.length > 0 ? (
                            students.map(student => (
                                <div key={student.id} className="student-card2">
                                    <p 
                                        className={`student-name ${
                                            student.attendanceStatus === "Available" ? "present-name" :
                                            student.attendanceStatus === "notAvailable" ? "absent-name" : ""
                                        }`}
                                    >
                                        {student.firstName} {student.lastName}
                                    </p>

                                    <div className="attendance-buttons">
                                        <FaWhatsapp color={student.preM ? "rgb(17, 228, 196)" : "red"} />
                                        <button 
                                            className={`attendance-button present-button ${student.attendanceStatus === "Available" ? "active" : ""}`} 
                                            onClick={() => markAttendance(student.id, "present", student.preM)}
                                        >
                                            נוכח
                                        </button>
                                        <button 
                                            className={`attendance-button absent-button ${student.attendanceStatus === "notAvailable" ? "disabled" : ""}`} 
                                            onClick={() => markAttendance(student.id, "absent", student.preM)}
                                            disabled={student.attendanceStatus === "notAvailable"}
                                        >
                                            לא נוכח
                                        </button>

                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No students found for this class.</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default CheckingStudentAttendance;
