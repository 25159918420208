import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../../Components/Firebase";
import '../../../Functions/Styles/Manager/Data/StudentsInClassCount.css';

function StudentsInSchoolCount() {
    const { userid } = useParams();
    const [studentCount, setStudentCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [schoolName, setSchoolName] = useState("");

    useEffect(() => {
        const fetchStudentCount = async () => {
            setLoading(true);
            setError(null);
            
            try {
                const userDocRef = doc(db, "Teachers", userid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const fetchedSchoolName = userData.schoolName || "Unknown School";
                    setSchoolName(fetchedSchoolName);

                    const studentsCollection = collection(db, "Schools", fetchedSchoolName, "Students");
                    const studentsSnapshot = await getDocs(studentsCollection);
                    
                    setStudentCount(studentsSnapshot.empty ? 0 : studentsSnapshot.docs.length);
                } else {
                    throw new Error("User not found.");
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
                setError("Error loading data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchStudentCount();
    }, [userid]);

    if (loading) return <p>טוען...</p>;
    if (error) return <h1>{error}</h1>;

    return (
        <React.Fragment>
            <h1 className="SC">{studentCount}</h1>
        </React.Fragment>
    );
}

export default StudentsInSchoolCount;
