import React, { useState, useRef, useEffect } from "react";
import { collection, getDocs, where, query, doc, setDoc } from "firebase/firestore";
import { db } from "../../../Components/Firebase";
import "../../../Styles/Pages/Manager/School/Calendar.css";
import Loading from "../../../Components/Loading";
import { useParams } from "react-router-dom";

function Calendar() {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [transformOrigin, setTransformOrigin] = useState("0 0");
  const stageRef = useRef();

  const [classes, setClasses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [loading, setLoading] = useState(true);
  const { schoolId } = useParams(); 

  const days = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי"];
  const times = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  useEffect(() => {
    const preventScroll = (e) => {
      if (e.type === "wheel" || e.type === "touchmove") {
        e.preventDefault();
      } else if (
        e.type === "keydown" &&
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Space"].includes(
          e.key
        )
      ) {
        e.preventDefault();
      }
    };

    const container = stageRef.current;
    container.addEventListener("wheel", preventScroll, { passive: false });
    window.addEventListener("keydown", preventScroll, { passive: false });
    container.addEventListener("touchmove", preventScroll, { passive: false });

    return () => {
      container.removeEventListener("wheel", preventScroll);
      window.removeEventListener("keydown", preventScroll);
      container.removeEventListener("touchmove", preventScroll);
    };
  }, []);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        let allClasses = [];
        const scheduleData = {};
        
        const layersSnapshot = await getDocs(collection(db, "Schools", schoolId, "Layers"));
    
        for (const layerDoc of layersSnapshot.docs) {
          const layerId = layerDoc.id;
          const classesSnapshot = await getDocs(collection(db, "Schools", schoolId, "Layers", layerId, "Classes"));
          
          const layerClasses = [];
          for (const classDoc of classesSnapshot.docs) {
            
            const scheduleSnapshot = await getDocs(
              collection(db, "Schools", schoolId, "Layers", layerId, "Classes", classDoc.id, "schedule")
            );
            
            scheduleSnapshot.forEach((dayDoc) => {
              const day = dayDoc.id;
              const daySchedule = dayDoc.data();
              
              Object.entries(daySchedule).forEach(([timeIndex, teacherId]) => {
                const timeString = times[timeIndex];
                if (!scheduleData[day]) scheduleData[day] = {};
                if (!scheduleData[day][timeString]) scheduleData[day][timeString] = {};
                scheduleData[day][timeString][classDoc.id] = teacherId;
              });
            });

            layerClasses.push({
              id: classDoc.id,
              schoolId,
              layerId,
              ...classDoc.data(),
            });
          }
    
          
          const color = `rgb(${Math.floor(Math.random() * 56) + 200}, ${Math.floor(Math.random() * 56) + 200}, ${Math.floor(Math.random() * 56) + 200})`;
          
          allClasses.push({
            layerId,
            classes: layerClasses,
            color: color,
          });
        }
    
        setClasses(allClasses);
        setSchedule(scheduleData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchTeachers = async () => {
      try {
        const teachersQuery = query(
          collection(db, "Teachers"),
          where("schoolName", "==", schoolId)
        );
        
        const teachersSnapshot = await getDocs(teachersQuery);
        const fetchedTeachers = teachersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setTeachers(fetchedTeachers);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchTeachers()

    fetchSchoolData();
  }, []);

  const handleTeacherSelect = (day, time, classId, teacherId) => {
    const isTeacherAssigned = Object.values(schedule[day]?.[time] || {}).some(
      (assignedTeacherId) => assignedTeacherId === teacherId
    );

    if (isTeacherAssigned) {
      alert("המורה כבר מוקצה לשיעור אחר באותו זמן.");
      return;
    }

    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [time]: {
          ...prev[day]?.[time],
          [classId]: teacherId,
        },
      },
    }));
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const newX = e.clientX - startPosition.x;
      const newY = e.clientY - startPosition.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleWheel = (e) => {
    const { clientX, clientY } = e;
    const container = stageRef.current;
    const bounds = container.getBoundingClientRect();

    const cursorX = clientX - bounds.left;
    const cursorY = clientY - bounds.top;

    const zoomFactor = e.deltaY < 0 ? 1.1 : 0.9;
    const newScale = Math.max(0.5, Math.min(3, scale * zoomFactor));

    const originX = (cursorX / bounds.width) * 100;
    const originY = (cursorY / bounds.height) * 100;

    setTransformOrigin(`${originX}% ${originY}%`);
    setScale(newScale);
  };

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 156) + 100;
    const g = Math.floor(Math.random() * 156) + 100;
    const b = Math.floor(Math.random() * 156) + 100;
    return `rgb(${r},${g},${b})`;
  };

  const handelSave = async () => {
    try {
      for (const [day, daySchedule] of Object.entries(schedule)) {
        for (const [time, timeSchedule] of Object.entries(daySchedule)) {
          for (const [classId, teacherId] of Object.entries(timeSchedule)) {
            if (teacherId) {
              
              const timeIndex = times.indexOf(time);
  
              if (timeIndex === -1) continue;
  
              
              const scheduleRef = collection(
                db,
                "Schools",
                schoolId,
                "Layers",
                classes
                  .find((layer) =>
                    layer.classes.some((cls) => cls.id === classId)
                  )?.layerId,
                "Classes",
                classId,
                "schedule"
              );
  
              
              const dayDocRef = doc(scheduleRef, day);
  
              
              await setDoc(
                dayDocRef,
                {
                  [timeIndex]: teacherId,
                },
                { merge: true }
              );
            }
          }
        }
      }
      alert("לוח הזמנים נשמר בהצלחה!");
    } catch (error) {
      console.error("שגיאה בשמירת לוח הזמנים:", error);
      alert("אירעה שגיאה במהלך שמירת לוח הזמנים.");
    }
  };
  


  return (
    <React.Fragment>

      <div className="Controls">

        <h1>לוח שנה</h1>

        <button onClick={() => handelSave()}>לשמור לוח שנה</button>

      </div>

<div
      className="calendar-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onWheel={handleWheel}
      ref={stageRef}
    >
      {loading ? (
        <Loading />
      ) : (
        <div
          className="calendar-schedule"
          style={{
            transform: `scale(${scale})`,
            transformOrigin: transformOrigin,
            left: `${position.x}px`,
            top: `${position.y}px`,
          }}
        >
          {days.map((day) => (
            <div className="calendar-day" key={day}>
              <h2 className="calendar-day-title">{day}</h2>
              <table className="calendar-table">
                <thead>
                  <tr>
                    <th className="calendar-table-header">שיעור</th>
                    {classes.map((layer) =>
                      layer.classes.map((cls) => (
                        <th
                          key={cls.id}
                          className="calendar-table-header"
                          style={{
                            backgroundColor: layer.color,
                          }}
                        >
                          {cls.name}
                        </th>
                      ))
                    )}
                  </tr>
                </thead>
                <tbody>
                  {times.map((time) => (
                    <tr key={time} dir="ltr">
                      <td className="calendar-table-cell">{time}</td>
                      {classes.map((layer) =>
                        layer.classes.map((cls) => (
                          <td key={cls.id} className="calendar-table-cell">
                            <select
                              value={schedule[day]?.[time]?.[cls.id] || ""}
                              onChange={(e) => handleTeacherSelect(day, time, cls.id, e.target.value)}
                              className="calendar-select"
                            >
                              <option value="" dir="rtl">
                                בחר מורה
                              </option>
                              {teachers.map((teacher) => (
                                <option key={teacher.id} value={teacher.id} dir="rtl">
                                  {teacher.firstName} {teacher.lastName}
                                </option>
                              ))}
                            </select>
                          </td>
                        ))
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
    </div>
    </React.Fragment>
  );
}

export default Calendar;
