import React, { useEffect, useState } from "react";
import { db } from "../../../Components/Firebase"; 
import { useParams } from "react-router-dom";

function FetchExitPreCount() {
    const [exitPreCount, setExitPreCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { userid } = useParams();
    const [schoolId, setSchoolId] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userSnapshot = await db.collection("Teachers").doc(userid).get();
                if (userSnapshot.exists) {
                    const userData = userSnapshot.data();
                    setSchoolId(userData.schoolName);
                } else {
                    setError("לא נמצאו פרטי משתמש.");
                }
            } catch (err) {
                setError("שגיאה במהלך שליפת נתוני המשתמש.");
            }
        };

        fetchUserData();
    }, [userid]);

    useEffect(() => {
        if (schoolId) {
            const fetchExitPreCount = async () => {
                try {
                    const today = new Date().toLocaleDateString();
                    const snapshot = await db.collection("Schools")
                        .doc(schoolId)
                        .collection("ExitPre").where("date", "==", today)
                        .get();

                    setExitPreCount(snapshot.size);
                } catch (err) {
                    setError("שגיאה במהלך שליפת מספר המסמכים.");
                } finally {
                    setLoading(false);
                }
            };

            fetchExitPreCount();
        }
    }, [schoolId]);

    if (loading) {
        return <p>טוען...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <React.Fragment>
            <h1 className="SC">{exitPreCount}</h1>
        </React.Fragment>
    );
}

export default FetchExitPreCount;
