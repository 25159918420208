import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import Logo from '../Images/Logo.webp';
import { useNavigate } from "react-router-dom";
import { auth } from "../Components/Firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

// Styles
import '../Styles/Pages/Landing/Sec-1.css';
import '../Styles/Pages/Landing/Sec-2.css';

function Landing() {
    const nav = useNavigate();
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        const detectMobileOrTablet = () => {
            const isMobileOrTabletDevice = /Mobi|Android|iPhone|iPad|iPod|Tablet|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 1024;
            setIsMobileOrTablet(isMobileOrTabletDevice);
        };

        detectMobileOrTablet();
    }, []);

    const handleLogin = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (user) {
                const userid = user.uid;
                nav(`/CheckUser/${userid}`);
            }
        } catch (error) {
            setError("התחברות נכשלה, בדוק את הדואר האלקטרוני או הסיסמה ונסה שוב.");
            console.error("Login error: ", error);
        }
    };

    return (
        <React.Fragment>
            <NavBar />

            <section className="sec-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    viewBox="0 0 1920 750"
                    preserveAspectRatio="xMidYMid meet"
                    className="svg-background"
                >
                    <path d="M0 0H1920V669.219C949.666 460.987 405.915 928.875 0 669.219V0Z" fill="url(#paint0_linear_63_642)" />
                    <defs>
                        <linearGradient id="paint0_linear_63_642" x1="0" y1="375" x2="1920" y2="375" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#00B2FF" />
                            <stop offset="1" stopColor="#1A58B6" />
                        </linearGradient>
                    </defs>
                </svg>

                <div className="onTop">
                    <div className="Block1">
                        <div className="Content1">
                            <h1>ברוכים הבאים</h1>
                            <p>הטכנולוגיה הטובה ביותר למערכת בתי הספר בישראל</p>
                            <button>לפנות אלינו</button>
                        </div>
                    </div>

                    <div className="Block2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 726 500" fill="none">
                            <path d="M0 30C0 13.4315 13.4315 0 30 0H688.339C714.664 0 728.469 31.8277 711.874 52.2643C598.902 191.394 603.444 292.871 719.127 450.05C734.117 470.418 720.097 500 694.808 500H30C13.4315 500 0 486.569 0 470V30Z" fill="white" />
                        </svg>

                        <div className="Content2">
                            {isMobileOrTablet ? (
                                <div className="app-buttons">
                                    <img src={Logo} alt="Logo" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()} />
                                    <a dir="rtl" href='https://mobile.teacherclick.co.il/' className="app-store-button" rel="noopener noreferrer">
                                        <button>כניסה לחשבון</button>
                                    </a>
                                </div>
                            ) : (
                                <>
                                    <h1>לחשבון שלי</h1>
                                    <div className="Authinputs">
                                        <input
                                            type="email"
                                            placeholder="דואר אלקטרוני"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <input
                                            type="password"
                                            placeholder="סיסמה"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    {error && <p dir="rtl" className="error-message">{error}</p>}
                                    <div className="AuthFunctions">
                                        <button style={{ background: '#00B2FF' }} onClick={handleLogin}>
                                            לחשבון שלי
                                        </button>
                                        <button onClick={() => nav('/CreateAccount')} style={{ background: '#FE4F5A' }}>
                                            רישום
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section className="Sec-2">
                <div>
                    <img src={Logo} alt="Logo" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()} />
                </div>

                <div dir="rtl">
                    <h1>מי אנחנו</h1>
                    <p>שלום,

אנו שמחים לעזור לך להפוך את בית הספר שלך לבית ספר טכנולוגי ואוטומטי. המערכת שלנו תספק לך תצוגה מלאה של מצב בית הספר, כולל מורים, תלמידים</p>
                    <button>לפנות אלינו</button>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Landing;
