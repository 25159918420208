import React, { useState, useEffect } from "react";
import Head from "../../../Components/Head";
import { useParams } from "react-router-dom";
import { db } from "../../../Components/Firebase";
import '../../../Styles/Pages/Manager/School/MessagesSettings.css';

function MessagesSettings() {
    const { userid, schoolid } = useParams();
    const [schoolData, setSchoolData] = useState(null);
    const [language, setLanguage] = useState("hebrew");
    const [studentName, setStudentName] = useState("");
    const [messageType, setMessageType] = useState("absence");
    const [message, setMessage] = useState("");

    useEffect(() => {
        const fetchSchoolData = async () => {
            try {
                const schoolDoc = await db.collection("Schools").doc(schoolid).get();
                if (schoolDoc.exists) {
                    setSchoolData(schoolDoc.data());
                    setLanguage(schoolDoc.data().MessagesLang || "hebrew");
                }
            } catch (error) {
                console.error("Error fetching school data:", error);
            }
        };

        fetchSchoolData();
    }, [schoolid]);

    const getMessagePreview = () => {
        const schoolName = schoolData?.School_Name || "בית ספר שלך";
        const student = studentName || "תלמיד";
        const currentDateTime = new Date().toLocaleString();

        if (messageType === "absence") {
            if (language === "hebrew") {
                return `היי ${student},\ לא בכיתה. אנא צור קשר עם ילדך.\nתאריך: ${currentDateTime}\n\n${schoolName}`;
            } else if (language === "arabic") {
                return `مرحبًا ${student},\ غير موجود في الصف. من فضلك اتصل طفلك.\nالتاريخ: ${currentDateTime}\n\n${schoolName}`;
            }
        } else if (messageType === "firstCheck") {
            if (language === "hebrew") {
                return `היי ${student},\ תגיע היום לבית הספר.\n${schoolName}`;
            } else if (language === "arabic") {
                return `مرحبًا ${student},\ سيصل اليوم إلى المدرسة.\n${schoolName}`;
            }
        } else if (messageType === "exitPermission") {
            if (language === "hebrew") {
                return `היי ${student},\ קיבלת אישור יציאה היום.\nתאריך: ${currentDateTime}\n\n${schoolName}`;
            } else if (language === "arabic") {
                return `مرحبًا ${student},\ تم منح إذن خروج اليوم.\nالتاريخ: ${currentDateTime}\n\n${schoolName}`;
            }
        }

        return "";
    };

    const handleSaveLanguage = async () => {
        try {
            await db.collection("Schools").doc(schoolid).update({
                MessagesLang: language,
            });
            setMessage("שפה נשמרה בהצלחה!");
        } catch (error) {
            console.error("Error saving language:", error);
            setMessage("שגיאה בעת שמירת השפה.");
        }
    };

    return (
        <React.Fragment>
            <Head />
            <div className="messages-settings" dir="rtl">
                <h1>הגדרות הודעות</h1>

                <label htmlFor="language-select">בחר שפת הודעה:</label>
                <select
                    id="language-select"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                >
                    <option value="hebrew">עברית</option>
                    <option value="arabic">ערבית</option>
                </select>

                <label htmlFor="student-name">שם תלמיד:</label>
                <input
                    type="text"
                    id="student-name"
                    value={studentName}
                    onChange={(e) => setStudentName(e.target.value)}
                    placeholder="הכנס שם תלמיד"
                />

                <label htmlFor="message-type">בחר סוג הודעה:</label>
                <select
                    id="message-type"
                    value={messageType}
                    onChange={(e) => setMessageType(e.target.value)}
                >
                    <option value="absence">הודעת היעדרות</option>
                    <option value="exitPermission">הודעת אישור יציאה</option>
                </select>

                <div className="message-preview">
                    <h2>תצוגה מקדימה של הודעה</h2>
                    <p>{getMessagePreview()}</p>
                </div>

                <button className="save-button2" onClick={handleSaveLanguage}>
                    שמור שפה
                </button>

                {message && <p className="success-message">{message}</p>}
            </div>
        </React.Fragment>
    );
}

export default MessagesSettings;
