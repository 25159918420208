import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Components/Firebase";
import Head from "../../Components/Head";
import Loading from "../../Components/Loading";
import "../../Styles/Pages/Manager/ViewExitPre.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import Logo from '../../Images/Logo.webp'

function ViewExitPre() {
  const { schoolId } = useParams();
  const [exitPreDocs, setExitPreDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filteredDocs, setFilteredDocs] = useState([]);

  useEffect(() => {
    const fetchExitPreDocs = async () => {
      try {
        const snapshot = await db
          .collection("Schools")
          .doc(schoolId)
          .collection("ExitPre")
          .get();

        const docsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setExitPreDocs(docsData);

        const todayFormatted = format(new Date(), "dd/MM/yyyy");
        const todayDocs = docsData.filter((doc) => {
          const docDate = format(new Date(doc.date), "dd/MM/yyyy");
          return docDate === todayFormatted;
        });

        setFilteredDocs(todayDocs);
      } catch (err) {
        setError("שגיאה במהלך שליפת המסמכים.");
      } finally {
        setLoading(false);
      }
    };

    fetchExitPreDocs();
  }, [schoolId]);

  useEffect(() => {
    if (selectedDate) {
      const filtered = exitPreDocs.filter((doc) => {
        const docDate = new Date(doc.date);
        const formattedDocDate = format(docDate, "dd/MM/yyyy");
        const formattedSelectedDate = format(selectedDate, "dd/MM/yyyy");
        return formattedDocDate === formattedSelectedDate;
      });
      setFilteredDocs(filtered);
    }
  }, [selectedDate, exitPreDocs]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePrint = (doc) => {
    const printWindow = window.open("teacherclick", "_blank");
    const printContent = `
      <html lang="he" dir="rtl">
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            padding: 20px;
            background-color: #f9f9f9;
          }
          .document-container {
            width: 100%;
            margin: auto;
            padding: 20px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          }
          .document-header {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 15px;
          }
          .document-info p {
            font-size: 1.2rem;
            margin: 5px 0;
          }
          .signature-preview img {
            margin-top: 10px;
            height: 80px;
            border: 1px solid #ddd;
            padding: 5px;
            border-radius: 5px;
            background-color: #f4f4f4;
          }
          .responsibility-statement {
            margin-top: 20px;
            font-size: 1rem;
            color: #555;
            line-height: 1.6;
          }
          .footer {
            margin-top: 30px;
            text-align: center;
            font-size: 1rem;
            color: #333;
          }
          .footer img {
            max-width: 100px; /* Adjust size of the logo */
          }
          .footer a {
            text-decoration: none;
            color: #333;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <div class="document-container">
          <div class="document-header">אישור יציאה</div>
          <p><strong>${doc.schoolId}</strong></p>
          <div class="document-info">
            <p><strong>שם התלמיד:</strong> ${doc.studentName}</p>
            <p><strong>תאריך:</strong> ${doc.date}</p>
            <p><strong>שעה:</strong> ${doc.time}</p>
            <p>${doc.responsibilityStatement}</p>
          </div>
          <div class="signature-preview">
            <strong>חתימה:</strong>
            <br />
            <img src="${doc.signature}" alt="חתימת הורה" />
          </div>
          <div class="responsibility-statement">
            <strong>הצהרת אחריות:</strong>
            <p>האחריות על הוצאת הילד/ה מחוץ לשטח בית הספר חלה על ההורים בלבד.</p>
            <p>אישור זה נמסר רק לצורך תיעוד ואין לו תוקף משפטי מעבר לכך.</p>
          </div>
        </div>
        <!-- Footer Section -->
        <div class="footer">
          <img src=${Logo} alt="Logo" /> <!-- Replace with actual logo URL -->
          <p><a href="http://teacherclick.co.il" target="_blank">teacherclick.co.il</a></p>
        </div>
        <script>
          window.onload = function() {
            window.print();
            window.close();
          }
        </script>
      </body>
      </html>
    `;
    printWindow.document.write(printContent);
    printWindow.document.close();
  };
  

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p className="exit-pre-error-message">{error}</p>;
  }

  return (
    <React.Fragment>
      <Head />
      <div className="date-picker-container">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          className="date-picker"
        />
      </div>
      <div className="exit-pre-container" dir="rtl">
        {filteredDocs.length > 0 ? (
          <div className="exit-pre-docs">
            {filteredDocs.map((doc) => (
              <div key={doc.id} className="exit-pre-item">
                <p>{doc.studentName}</p>
                <p>{doc.date}</p>
                <p>{doc.time}</p>
                <button
                  className="print-button"
                  onClick={() => handlePrint(doc)}
                >
                  הדפס
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>לא נמצאו מסמכי אישור יציאה</p>
        )}
      </div>
    </React.Fragment>
  );
}

export default ViewExitPre;
