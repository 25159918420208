import React, { useState } from "react";
import { db } from "../../../Components/Firebase";

function AddNewStudent({ onClose, schoolId, classid }) {
    
    const [studentData, setStudentData] = useState({
        firstName: "",
        lastName: "",
        birthday: "",
        dadName: "",
        momName: "",
        gender: "",
        phoneNumber1: "",
        phoneNumber2: "",
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStudentData({ ...studentData, [name]: value });
    };

    const validateFields = () => {
        const newErrors = {};
        const requiredFields = ["firstName", "lastName", "birthday", "dadName", "momName", "gender", "phoneNumber1"];
        
        requiredFields.forEach(field => {
            if (!studentData[field].trim()) {
                newErrors[field] = "שדה זה הוא שדה חובה";
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (validateFields()) {
            const { firstName, lastName, birthday, dadName, momName, gender, phoneNumber1, phoneNumber2 } = studentData;
            try {
                await db.collection("Schools").doc(schoolId).collection("Students").add({
                    firstName,
                    lastName,
                    birthday,
                    dadName,
                    momName,
                    gender,
                    class: classid,
                    phoneNumbers: [phoneNumber1, phoneNumber2].filter(Boolean),
                    createdAt: new Date(),
                    preM: true,
                    status: 'Available'
                });
                console.log("Student added:", studentData);
                onClose();
            } catch (error) {
                console.error("Error adding student: ", error);
            }
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>הוסף תלמיד חדש</h2>
                <input
                    type="text"
                    name="firstName"
                    placeholder="שם פרטי"
                    value={studentData.firstName}
                    onChange={handleInputChange}
                    className="modal-input"
                />
                {errors.firstName && <p className="error">{errors.firstName}</p>}

                <input
                    type="text"
                    name="lastName"
                    placeholder="שם משפחה"
                    value={studentData.lastName}
                    onChange={handleInputChange}
                    className="modal-input"
                />
                {errors.lastName && <p className="error">{errors.lastName}</p>}

                <input
                    type="date"
                    name="birthday"
                    placeholder="תאריך לידה"
                    value={studentData.birthday}
                    onChange={handleInputChange}
                    className="modal-input"
                />
                {errors.birthday && <p className="error">{errors.birthday}</p>}

                <input
                    type="text"
                    name="dadName"
                    placeholder="שם האב"
                    value={studentData.dadName}
                    onChange={handleInputChange}
                    className="modal-input"
                />
                {errors.dadName && <p className="error">{errors.dadName}</p>}

                <input
                    type="text"
                    name="momName"
                    placeholder="שם האם"
                    value={studentData.momName}
                    onChange={handleInputChange}
                    className="modal-input"
                />
                {errors.momName && <p className="error">{errors.momName}</p>}

                <select
                    name="gender"
                    value={studentData.gender}
                    onChange={handleInputChange}
                    className="modal-input"
                >
                    <option value="">בחר מין</option>
                    <option value="זכר">זכר</option>
                    <option value="נקבה">נקבה</option>
                </select>
                {errors.gender && <p className="error">{errors.gender}</p>}

                <input
                    type="tel"
                    name="phoneNumber1"
                    placeholder="מספר טלפון 1"
                    value={studentData.phoneNumber1}
                    onChange={handleInputChange}
                    className="modal-input"
                />
                {errors.phoneNumber1 && <p className="error">{errors.phoneNumber1}</p>}

                <input
                    type="tel"
                    name="phoneNumber2"
                    placeholder="מספר טלפון 2 (לא חובה)"
                    value={studentData.phoneNumber2}
                    onChange={handleInputChange}
                    className="modal-input"
                />

                <div className="modal-actions">
                    <button className="modal-button cancel" onClick={onClose}>ביטול</button>
                    <button className="modal-button save" onClick={handleSave}>שמור</button>
                </div>
            </div>
        </div>
    );
}

export default AddNewStudent;
